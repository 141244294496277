/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      date
      excerpt
      permalink
      image
      image_alt
      thumb_image
      title
      description
      title_seo
      author
      text
      published
      thumb_image_alt
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      date
      excerpt
      permalink
      image
      image_alt
      thumb_image
      title
      description
      title_seo
      author
      text
      published
      thumb_image_alt
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      date
      excerpt
      permalink
      image
      image_alt
      thumb_image
      title
      description
      title_seo
      author
      text
      published
      thumb_image_alt
      createdAt
      updatedAt
    }
  }
`;
export const createReminder = /* GraphQL */ `
  mutation CreateReminder(
    $input: CreateReminderInput!
    $condition: ModelReminderConditionInput
  ) {
    createReminder(input: $input, condition: $condition) {
      id
      name_reminder
      frequency
      repeat
      start_date
      end_date
      preferencesID
      share_reminder
      notification_type
      notification_time
      notification_param
      notification_phone
      notification_email
      checked_phone
      checked_email
      day_param
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReminder = /* GraphQL */ `
  mutation UpdateReminder(
    $input: UpdateReminderInput!
    $condition: ModelReminderConditionInput
  ) {
    updateReminder(input: $input, condition: $condition) {
      id
      name_reminder
      frequency
      repeat
      start_date
      end_date
      preferencesID
      share_reminder
      notification_type
      notification_time
      notification_param
      notification_phone
      notification_email
      checked_phone
      checked_email
      day_param
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReminder = /* GraphQL */ `
  mutation DeleteReminder(
    $input: DeleteReminderInput!
    $condition: ModelReminderConditionInput
  ) {
    deleteReminder(input: $input, condition: $condition) {
      id
      name_reminder
      frequency
      repeat
      start_date
      end_date
      preferencesID
      share_reminder
      notification_type
      notification_time
      notification_param
      notification_phone
      notification_email
      checked_phone
      checked_email
      day_param
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPreference = /* GraphQL */ `
  mutation CreatePreference(
    $input: CreatePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    createPreference(input: $input, condition: $condition) {
      id
      rec_dosing_sms
      rec_dosing_email
      rec_presc_sms
      rec_presc_email
      email_or_sms
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePreference = /* GraphQL */ `
  mutation UpdatePreference(
    $input: UpdatePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    updatePreference(input: $input, condition: $condition) {
      id
      rec_dosing_sms
      rec_dosing_email
      rec_presc_sms
      rec_presc_email
      email_or_sms
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePreference = /* GraphQL */ `
  mutation DeletePreference(
    $input: DeletePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    deletePreference(input: $input, condition: $condition) {
      id
      rec_dosing_sms
      rec_dosing_email
      rec_presc_sms
      rec_presc_email
      email_or_sms
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPet = /* GraphQL */ `
  mutation CreatePet(
    $input: CreatePetInput!
    $condition: ModelPetConditionInput
  ) {
    createPet(input: $input, condition: $condition) {
      id
      name_pet
      age_pet
      breed_pet
      color_pet
      weight_pet
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      name_vet
      createdAt
      updatedAt
    }
  }
`;
export const updatePet = /* GraphQL */ `
  mutation UpdatePet(
    $input: UpdatePetInput!
    $condition: ModelPetConditionInput
  ) {
    updatePet(input: $input, condition: $condition) {
      id
      name_pet
      age_pet
      breed_pet
      color_pet
      weight_pet
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      name_vet
      createdAt
      updatedAt
    }
  }
`;
export const deletePet = /* GraphQL */ `
  mutation DeletePet(
    $input: DeletePetInput!
    $condition: ModelPetConditionInput
  ) {
    deletePet(input: $input, condition: $condition) {
      id
      name_pet
      age_pet
      breed_pet
      color_pet
      weight_pet
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      name_vet
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      name_user
      mobile_user
      Pet {
        id
        name_pet
        age_pet
        breed_pet
        color_pet
        weight_pet
        name_vet
        createdAt
        updatedAt
      }
      Reminder {
        id
        name_reminder
        frequency
        repeat
        start_date
        end_date
        preferencesID
        share_reminder
        notification_type
        notification_time
        notification_param
        notification_phone
        notification_email
        checked_phone
        checked_email
        day_param
        createdAt
        updatedAt
      }
      ReminderPrescription {
        id
        name_reminder
        frequency
        repeat
        start_date
        end_date
        preferencesID
        share_reminder
        notification_type
        notification_time
        notification_param
        notification_phone
        notification_email
        checked_phone
        checked_email
        day_param
        createdAt
        updatedAt
      }
      Preference {
        id
        rec_dosing_sms
        rec_dosing_email
        rec_presc_sms
        rec_presc_email
        email_or_sms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      name_user
      mobile_user
      Pet {
        id
        name_pet
        age_pet
        breed_pet
        color_pet
        weight_pet
        name_vet
        createdAt
        updatedAt
      }
      Reminder {
        id
        name_reminder
        frequency
        repeat
        start_date
        end_date
        preferencesID
        share_reminder
        notification_type
        notification_time
        notification_param
        notification_phone
        notification_email
        checked_phone
        checked_email
        day_param
        createdAt
        updatedAt
      }
      ReminderPrescription {
        id
        name_reminder
        frequency
        repeat
        start_date
        end_date
        preferencesID
        share_reminder
        notification_type
        notification_time
        notification_param
        notification_phone
        notification_email
        checked_phone
        checked_email
        day_param
        createdAt
        updatedAt
      }
      Preference {
        id
        rec_dosing_sms
        rec_dosing_email
        rec_presc_sms
        rec_presc_email
        email_or_sms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      name_user
      mobile_user
      Pet {
        id
        name_pet
        age_pet
        breed_pet
        color_pet
        weight_pet
        name_vet
        createdAt
        updatedAt
      }
      Reminder {
        id
        name_reminder
        frequency
        repeat
        start_date
        end_date
        preferencesID
        share_reminder
        notification_type
        notification_time
        notification_param
        notification_phone
        notification_email
        checked_phone
        checked_email
        day_param
        createdAt
        updatedAt
      }
      ReminderPrescription {
        id
        name_reminder
        frequency
        repeat
        start_date
        end_date
        preferencesID
        share_reminder
        notification_type
        notification_time
        notification_param
        notification_phone
        notification_email
        checked_phone
        checked_email
        day_param
        createdAt
        updatedAt
      }
      Preference {
        id
        rec_dosing_sms
        rec_dosing_email
        rec_presc_sms
        rec_presc_email
        email_or_sms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVet = /* GraphQL */ `
  mutation CreateVet(
    $input: CreateVetInput!
    $condition: ModelVetConditionInput
  ) {
    createVet(input: $input, condition: $condition) {
      id
      email_vet
      name_vet
      address1_vet
      address2_vet
      city_vet
      state_vet
      zip_vet
      phone_vet
      createdAt
      updatedAt
    }
  }
`;
export const updateVet = /* GraphQL */ `
  mutation UpdateVet(
    $input: UpdateVetInput!
    $condition: ModelVetConditionInput
  ) {
    updateVet(input: $input, condition: $condition) {
      id
      email_vet
      name_vet
      address1_vet
      address2_vet
      city_vet
      state_vet
      zip_vet
      phone_vet
      createdAt
      updatedAt
    }
  }
`;
export const deleteVet = /* GraphQL */ `
  mutation DeleteVet(
    $input: DeleteVetInput!
    $condition: ModelVetConditionInput
  ) {
    deleteVet(input: $input, condition: $condition) {
      id
      email_vet
      name_vet
      address1_vet
      address2_vet
      city_vet
      state_vet
      zip_vet
      phone_vet
      createdAt
      updatedAt
    }
  }
`;
