/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      date
      excerpt
      permalink
      image
      image_alt
      thumb_image
      title
      description
      title_seo
      author
      text
      published
      thumb_image_alt
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        excerpt
        permalink
        image
        image_alt
        thumb_image
        title
        description
        title_seo
        author
        text
        published
        thumb_image_alt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReminder = /* GraphQL */ `
  query GetReminder($id: ID!) {
    getReminder(id: $id) {
      id
      name_reminder
      frequency
      repeat
      start_date
      end_date
      preferencesID
      share_reminder
      notification_type
      notification_time
      notification_param
      notification_phone
      notification_email
      checked_phone
      checked_email
      day_param
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReminders = /* GraphQL */ `
  query ListReminders(
    $filter: ModelReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name_reminder
        frequency
        repeat
        start_date
        end_date
        preferencesID
        share_reminder
        notification_type
        notification_time
        notification_param
        notification_phone
        notification_email
        checked_phone
        checked_email
        day_param
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPreference = /* GraphQL */ `
  query GetPreference($id: ID!) {
    getPreference(id: $id) {
      id
      rec_dosing_sms
      rec_dosing_email
      rec_presc_sms
      rec_presc_email
      email_or_sms
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPreferences = /* GraphQL */ `
  query ListPreferences(
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rec_dosing_sms
        rec_dosing_email
        rec_presc_sms
        rec_presc_email
        email_or_sms
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPet = /* GraphQL */ `
  query GetPet($id: ID!) {
    getPet(id: $id) {
      id
      name_pet
      age_pet
      breed_pet
      color_pet
      weight_pet
      User {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      name_vet
      createdAt
      updatedAt
    }
  }
`;
export const listPets = /* GraphQL */ `
  query ListPets(
    $filter: ModelPetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name_pet
        age_pet
        breed_pet
        color_pet
        weight_pet
        name_vet
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name_user
      mobile_user
      Pet {
        id
        name_pet
        age_pet
        breed_pet
        color_pet
        weight_pet
        name_vet
        createdAt
        updatedAt
      }
      Reminder {
        id
        name_reminder
        frequency
        repeat
        start_date
        end_date
        preferencesID
        share_reminder
        notification_type
        notification_time
        notification_param
        notification_phone
        notification_email
        checked_phone
        checked_email
        day_param
        createdAt
        updatedAt
      }
      ReminderPrescription {
        id
        name_reminder
        frequency
        repeat
        start_date
        end_date
        preferencesID
        share_reminder
        notification_type
        notification_time
        notification_param
        notification_phone
        notification_email
        checked_phone
        checked_email
        day_param
        createdAt
        updatedAt
      }
      Preference {
        id
        rec_dosing_sms
        rec_dosing_email
        rec_presc_sms
        rec_presc_email
        email_or_sms
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name_user
        mobile_user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVet = /* GraphQL */ `
  query GetVet($id: ID!) {
    getVet(id: $id) {
      id
      email_vet
      name_vet
      address1_vet
      address2_vet
      city_vet
      state_vet
      zip_vet
      phone_vet
      createdAt
      updatedAt
    }
  }
`;
export const listVets = /* GraphQL */ `
  query ListVets(
    $filter: ModelVetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email_vet
        name_vet
        address1_vet
        address2_vet
        city_vet
        state_vet
        zip_vet
        phone_vet
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
