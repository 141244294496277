import React from 'react';
import _ from 'lodash';
import {Helmet} from 'react-helmet';
import Header from './../../components/Header';
import Footer from './../../components/Footer';
import {Link, withPrefix, classNames} from '../../utils';
import Action from './../../components/Action';
import moment from 'moment-strftime';
import {graphql} from 'gatsby';

import {Layout} from '../../components/index';
import {htmlToReact} from '../../utils';

import Amplify, { API, graphqlOperation, Auth, DataStore, Predicates }  from "aws-amplify";
import awsConfig from "../../aws-exports";
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react'
import * as Queries from '../../graphql/queries'
import { 
  createUser as CreateUser, 
  createPet as CreatePet,
  createPreference as CreatePreference, 
  updateUser as UpdateUser 
} from '../../graphql/mutations'
import { log } from 'xstate/lib/actions';

Amplify.configure(awsConfig); 
// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export default class Post extends React.Component {
    constructor(props) {
      super(props);
      this.propsi = { ...this.props };
      this.dataset = {};
      this.onQuery = this.onQuery.bind(this)
    }

    onQuery = async () => {
      const postSlug = this.props.post
      console.log('query in post', postSlug);
      let datas = await API.graphql(
        graphqlOperation(Queries.listPosts, {
          filter: { permalink: { eq: postSlug } },
        })
      );
      let filteredPost = await datas.data.listPosts.items[0];
      console.log("filteredPost", filteredPost);
      this.dataset = filteredPost;
      setTimeout(() => {
        console.log("set timeout onQuery");
      }, 100);
      return filteredPost
    }
    
    async componentWillMount() {
      await this.onQuery();
      setTimeout(() => {
        console.log("set timeout componentwillmount");
        this.forceUpdate();
      }, 100);
    }

    render() {
      console.log('this.props in post', this.props.path);
      setTimeout(() => {
        console.log("set timeout")
        this.onQuery();
        console.log("set timeout-1")
      }, 100);
      this.propsi = {...this.props}
      let siteInfo = {
        data: {},
        pathPrefix: "/blog/",
        siteMetadata: {
          accent_color: "pink",
          color_scheme: "light",
          footer: {},
          header: {
            has_nav: true,
            nav_links: [
              {label: "Home", url: "/", style: "link"},
              {label: "account area", url: "/area/", style: "button"},
              {label: "Blog", url: "/blog/", style: "link"},
              {label: "About", url: "/about/", style: "link"},
              {label: "Dosing reminder", url: "/dosing-reminder/", style: "button"},
              {label: "Prescription reminder", url: "/prescription-reminder/", style: "button"},
              {label: "Contact", url: "/contact/", style: "link"},
            ],
          },
          footer: {
            content: "&copy; Nobatol. All rights reserved. This Jamstack site was created by Djadavin.",
            has_social: true,
            social_links: [
              {
                icon: "twitter",
                label: "Twitter",
                new_window: true,
                style: "icon",
                url: "https://twitter.com/",
              },
              {
                icon: "instagram",
                label: "Instagram",
                new_window: true,
                style: "icon",
                url: "https://www.instagram.com/",
              },
              {
                icon: "facebook",
                label: "Facebook",
                new_window: true,
                style: "icon",
                url: "https://dribbble.com/",
              },
              {
                icon: "linkedin",
                label: "LinkedIn",
                new_window: true,
                style: "icon",
                url: "https://www.linkedin.com/",
              }
            ]
          }
          ,
          
          title: "Nobatol",
        },
      }
      let contextIfo = {
        base: "",
        frontmatter : this.dataset,
        html: this.dataset.text,
        menu : {},
        name: "",
        relativeDir: "blog",
        relativePath: "",
        site: siteInfo,
        url : this.propsi.location.pathname,
      }
      this.propsi.pageContext = contextIfo
      console.log("this.propsi.pageContext", this.propsi.pageContext);
      console.log("this.dataset",this.dataset);
        return (
          
          <div id="page" className="site">
            <header id="masthead" className="site-header outer">
              <div className="inner">
                <div className="site-header-inside">
                  <div className="site-branding">
                    {_.get(this.propsi, 'pageContext.site.siteMetadata.header.logo_img', null) ? (
                    <p className="site-logo">
                      <Link to={withPrefix('/')}><img src={withPrefix(_.get(this.propsi, 'pageContext.site.siteMetadata.header.logo_img', null))} alt={_.get(this.propsi, 'pageContext.site.siteMetadata.header.logo_img_alt', null)} /></Link>
                    </p>
                    ) : 
                    <p className="site-title"><Link to={withPrefix('/')}>Nobatol</Link></p>
                    }
                  </div>
                  {_.get(this.propsi, 'pageContext.site.siteMetadata.header.has_nav', null) && (<React.Fragment>
                  <button id="menu-open" className="menu-toggle"><span className="screen-reader-text">Close Menu</span><span className="icon-menu" aria-hidden="true" /></button>
                  <nav id="main-navigation" className="site-navigation" aria-label="Main Navigation">
                    <div className="site-nav-inside">
                      <button id="menu-close" className="menu-toggle"><span className="screen-reader-text">Open Menu</span><span className="icon-close" aria-hidden="true" /></button>
                      <ul className="menu">
                        {_.map(_.get(this.propsi, 'pageContext.site.siteMetadata.header.nav_links', null), (action, action_idx) => {
                            let pageUrl = _.trim(_.get(this.propsi, 'pageContext.url', null), '/');
                            let actionUrl = _.trim(_.get(action, 'url', null), '/');
                            return (
                              <li key={action_idx} className={classNames('menu-item', {'current-menu-item': pageUrl === actionUrl, 'menu-button': _.get(action, 'style', null) !== 'link'})}>
                                <Action {...this.propsi} action={action} />
                              </li>
                            )
                        })}
                      </ul>
                      
                    </div>
                    
                  </nav>
                  </React.Fragment>)}
                </div>
              </div>
            </header>
            <main id="content" className="site-content">
              <div className="inner outer">
                <article className="post post-full">
                  <header className="post-header inner-sm">
                    <h1 className="post-title line-top">{_.get(this.propsi, 'pageContext.frontmatter.title', null)}</h1>
                    {_.get(this.propsi, 'pageContext.frontmatter.subtitle', null) && (
                    <div className="post-subtitle">
                      {htmlToReact(_.get(this.propsi, 'pageContext.frontmatter.subtitle', null))}
                    </div>
                    )}
                  </header>
                  {_.get(this.propsi, 'pageContext.frontmatter.image', null) && (
                  <div className="post-image">
                    <img src={withPrefix(_.get(this.propsi, 'pageContext.frontmatter.image', null))} alt={_.get(this.propsi, 'pageContext.frontmatter.image_alt', null)} />
                  </div>
                  )}
                  <div className="post-content inner-sm">
                    {htmlToReact(this.dataset.text)}
                  </div>
                  <footer className="post-meta inner-sm">
                    <time className="published" dateTime={moment(_.get(this.propsi, 'pageContext.frontmatter.date', null)).strftime('%Y-%m-%d %H:%M')}>{moment(_.get(this.propsi, 'pageContext.frontmatter.date', null)).strftime('%B %d, %Y')}</time>
                  </footer>
                </article>
              </div>
            </main>
            <footer id="colophon" className="site-footer outer">
              <div className="inner">
                <div className="site-footer-inside">
                  <div className="site-info">
                    {_.get(this.propsi, 'pageContext.site.siteMetadata.footer.content', null) && (
                    <span className="copyright">{htmlToReact(_.get(this.propsi, 'pageContext.site.siteMetadata.footer.content', null))}</span>
                    )}
                    {_.map(_.get(this.propsi, 'pageContext.site.siteMetadata.footer.links', null), (action, action_idx) => (
                      <ActionLink key={action_idx} {...this.propsi} action={action} />
                    ))}
                  </div>
                  {_.get(this.propsi, 'pageContext.site.siteMetadata.footer.has_social', null) && (
                  <div className="social-links">
                    {_.map(_.get(this.propsi, 'pageContext.site.siteMetadata.footer.social_links', null), (action, action_idx) => (
                      <Action key={action_idx} {...this.propsi} action={action} />
                    ))}
                  </div>
                  )}
                </div>
              </div>
            </footer>
          </div>
        
      );
    }
}


